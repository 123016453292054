<template>
  <div class="container" style="margin-top:70px;">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">Contact Us</h5>
    </div>
    <hr>

    <form @submit.prevent="handleFormSubmission" autocomplete="false" v-if="!successful">


      <div class="form-group">
        <label for="subject">Subject</label>
        <input name="subject" type="text" class="form-control" v-model="subject"  @change="validateSubject()"/>
        <ul class="error-feedback" v-if="subjectErrors.length > 0" v-html="subjectErrors"></ul>
      </div>

      <div class="form-group">
        <label for="content">Message</label>
        <textarea rows="10" name="content" type="text" class="form-control" v-model="content"  @change="validateContent()"></textarea>
        <ul class="error-feedback" v-if="contentErrors.length > 0" v-html="contentErrors"></ul>
      </div>



      <div class="form-group">
        <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                  ></span>
          Send
        </button>
      </div>


    </form>

    <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'" v-html="message"
    >
    </div>
  </div>

</template>

<script>


export default {
  name: 'ContactUs',
  components: {
  },
  data(){


    return {
      successful: false,
      loading: false,
      message: "",
      subject:'',
      subjectErrors:'',
      content:'',
      contentErrors:'',
      hasValidationError: false,
      formData:{},
      num:10,

    };
  },
  computed:{

  },
  methods:{


    handleFormSubmission(){
      this.$root.playClickSound();

      this.hasValidationError = false;
      this.message = "";
      this.successful = false;


      this.validateSubject();
      this.validateContent();
      if(this.hasValidationError) return;

      this.loading = true;

      this.axios
          .post(this.$hostApi,{task:'contactUs', subject:this.subject, content:this.content},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.message = 'Thanks for your message! We will review it and get back to you shortly.';
            this.successful = true;

            this.loading = false;
            //console.log(response);
          })
          .catch(error => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          });

    },

    validateSubject(){
      this.subjectErrors = this.$root.validateLength(this.subject,3,200);
      if(this.subjectErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateContent(){
      this.contentErrors = this.$root.validateLength(this.content,5,480);
      if(this.contentErrors.length > 0){
        this.hasValidationError = true;
      }
    },

  },
  created() {


  },
  mounted() {
    document.title = "Contract Us";
  },

}
</script>

<style>
.form-group{
  margin:15px 0;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.small, small {
  font-size: 80% !important;
  font-weight: 400;
}

.required:after {
  content: " *";
  color: red;
}

</style>